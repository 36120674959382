import React, { useState, useEffect, forwardRef } from 'react'
import * as THREE from 'three'
import { Circle, Sphere, Line, MeshDistortMaterial } from '@react-three/drei'
import { useSpring, animated } from '@react-spring/three'

import {
  cartesianXyzToViewXyz,
  degreeToRadian,
  getCartesianBySpherical,
} from 'src/components/composite/beamformers/Beam3DControl/tools'
import useDrag from '../useDrag'

import {
  red,
  green,
} from 'src/components/composite/beamformers/Beam3DControl/color'
import { phiMax } from 'src/constants/beamformers'

const BallAndDisk = ({ power, theta, thetaMax, phi, dragProps }) => {
  // ball
  // ball
  // ball
  // ball
  // ball
  // ball
  // 先把 球座標 theta phi 轉笛卡爾 xyz 再轉 view xyz (因為目前沒寫 球座標轉 View xyz)
  const [cX, cY, cZ] = getCartesianBySpherical([
    power,
    degreeToRadian(theta),
    degreeToRadian(phi),
  ])
  const [vx, vy, vz] = cartesianXyzToViewXyz(cX, cY, cZ)
  const thetaPosition = [vx, vy, vz]
  const phiPosition = [vx, 0, vz]

  // Disk
  // Disk
  // Disk
  // Disk
  // Disk
  // https://zh.wikipedia.org/wiki/%E7%90%83%E5%BA%A7%E6%A8%99%E7%B3%BB
  // 從 球座標 -> 圓柱座標(ρ, φ, ζ)
  const _rho = power * Math.sin(degreeToRadian(+theta)) // ρ
  const _zeta = power * Math.cos(degreeToRadian(+theta)) // ζ

  const _thetaDiskDensity = theta
  const _phiDiskDensity = phi / 2

  const thetaDiskProps = {
    rotation: [0, degreeToRadian(+phi + 180), 0], // 水平旋轉值 / + 180 = 讓 theta 位置等同滑鼠
    args: [power, _thetaDiskDensity, degreeToRadian(90), degreeToRadian(theta)],
    opacity: 0.2,
  }
  const phiDiskProps = {
    rotation: [degreeToRadian(-90), 0, 0], //  -90 = degree 讓圓餅變水平
    args: [_rho, _phiDiskDensity, degreeToRadian(0), degreeToRadian(+phi)],
    opacity: 0.3,
  }

  return (
    <>
      <Disk color='#C4C4C4' position={[0, -0.1, 0]} {...thetaDiskProps} />
      <Ball name='theta' color={red} position={thetaPosition} {...dragProps} />
      <Disk color='#2C31A6' position={[0, -0.1, 0]} {...phiDiskProps} />
      <Ball name='phi' color={green} position={phiPosition} {...dragProps} />
      <DottedLine
        rotation={[0, degreeToRadian(+phi), 0]}
        start={[_rho, _zeta, 0]}
        end={[_rho, 0, 0]}
        show={_rho && _zeta}
      />
    </>
  )
}

export default BallAndDisk

const Disk = forwardRef((props, ref) => {
  const { color, args, rotation, position, opacity = 0.1 } = props

  return (
    <Circle ref={ref} args={args} rotation={rotation} position={position}>
      <meshBasicMaterial
        wireframe
        side={THREE.DoubleSide}
        color={color}
        transparent
        opacity={opacity}
      />
    </Circle>
  )
})

const DottedLine = ({ rotation, start, end, show }) => {
  return (
    <Line
      rotation={rotation}
      points={[start, end]}
      color='white'
      lineWidth={1}
      dashed
      dashSize={0.1}
      gapSize={0.2}
      transparent
      opacity={show ? 0.5 : 0}
    />
  )
}

const AnimatedSphere = animated(Sphere)
const AnimateMeshDistortMaterial = animated(MeshDistortMaterial)
const Ball = forwardRef((props, ref) => {
  const {
    name,
    color: propsColor,
    position,
    onDragStart,
    onDrag,
    onDragEnd,
  } = props
  const [hover, setHover] = useState(false)
  const [drag, setDrag] = useState(false)

  const bind = useDrag({
    name,
    onDragStart,
    onDrag: (name, mouse3D) => {
      setDrag(true)
      onDrag(name, mouse3D)
    },
    onDragEnd: event => {
      setDrag(false)
      onDragEnd(event)
    },
    onHover: e => setHover(e.hovering),
  })

  useEffect(() => {
    if (drag) document.body.style.cursor = 'grabbing'
    if (hover && !drag) document.body.style.cursor = 'grab'
    if (!hover && !drag) document.body.style.cursor = 'auto'
    return () => (document.body.style.cursor = 'auto')
  }, [hover, drag])

  const blurSmallSize = 1.1
  const blurBigSize = 1.4
  const hoverSize = 1.5
  const [{ scale, color, opacity }] = useSpring(
    () => ({
      from: {
        scale: drag ? hoverSize : blurSmallSize,
        color: drag ? propsColor : '#fff',
        opacity: 0.6,
      },
      to: {
        scale: drag ? hoverSize : blurBigSize,
        color: propsColor,
        opacity: 1,
      },
      loop: {
        reverse: true,
      },
      config: {
        duration: 500,
      },
    }),
    [drag]
  )

  return (
    <AnimatedSphere
      {...bind()}
      ref={ref}
      args={[0.5]}
      scale={scale}
      position={position}>
      <AnimateMeshDistortMaterial
        roughness={1}
        color={color}
        transparent
        opacity={opacity}
        emissive={color}
        envMapIntensity={1.5}
      />
    </AnimatedSphere>
  )
})
